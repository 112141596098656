<template>
<div class="login" v-show="notloggedin">
  <validation-observer
    ref="observer"
  >
    <form @submit.prevent="submit">
      <validation-provider
        v-slot="{ errors }"
        name="Username"
        rules="required|min:5"
      >
        <v-text-field
          v-model="username"
          :error-messages="errors"
          label="Username"
          required
          dark
          color="white"
        ></v-text-field>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        name="Password"
        rules="required|min:8"
      >
        <v-text-field
            v-model="password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            :error-messages="errors"
            label="Password"
            class="input-group--focused"
            @click:append="show = !show"
            color="white"
            dark
            required
        ></v-text-field>
      </validation-provider>

      <v-btn
        class="mr-4"
        type="submit"
      >
        submit
      </v-btn>
    </form>
  </validation-observer>
  <v-alert outlined type="error" text :value="invalidcredentials"> Invalid Credentials </v-alert>
</div>
</template>
<script>
  import { required, min } from 'vee-validate/dist/rules'
  import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
  import axios from "axios";

  setInteractionMode('eager')

  extend('required', {
    ...required,
    message: '{_field_} can not be empty',
  })

  extend('min', {
    ...min,
    message: '{_field_} must have atlest {length} characters',
  })

  export default {
    name: 'Login',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data: () => ({
      show: false,
      current_time: '',
      notloggedin: true,
      invalidcredentials: false,
    }),

    mounted () {
        this.checkloggedin();
    },

    methods: {
      submit () {
        if (this.username.length > 4 && this.password.length > 7) {
          let data = {
            username : this.username,
            password : this.password,
          };
          let config = {
            method : 'post',
            url : `https://kharame-devices.herokuapp.com/login`,
            data: data
          };
          axios(config)
          .then((response) => {
            if (response.data && response.data.access_token && response.data.status === "S") {
              sessionStorage.setItem("token", response.data.access_token)
              sessionStorage.setItem("data", JSON.stringify(response.data))
              sessionStorage.setItem("expire_time", response.data.expire_time)
              this.$router.push("/account");
            }
          })
          .catch((error) => {
            this.invalidcredentials = true;
          })
        }
      },
      checkloggedin() {
        this.current_time = Math.round(+new Date()/1000);
        if (sessionStorage.getItem("token") && sessionStorage.getItem("expire_time") > this.current_time) {
          this.notloggedin= false;
          this.$router.push("/account")
        } else {
          sessionStorage.clear();
        }
      },
    },
  }
</script>
<style scoped>
.login {
  width : 60%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 6%;
  padding : 10%;
  border: solid;
  border-radius: 10px;
  border-color: white;
}
.mr-4 {
  margin-top: 2%;
  margin-bottom: 2%;
}
</style>